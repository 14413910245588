import React from 'react';
import GatsbyImage from 'gatsby-image';
import { Container, Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { removeHashSignFromLink } from 'helpers/utils/url';
import { GatsbyFluidImage } from 'types/gatsby/image';

import styles from './SectionWrapper.module.scss';

interface FeatureSectionProps {
  title: string;
  titleImage: GatsbyFluidImage;
  description: string;
  hashTag: string;
  showBreakline?: boolean;
}

const FeatureSectionWrapper: React.FC<FeatureSectionProps> = ({
  children,
  title,
  titleImage,
  description,
  hashTag,
  showBreakline = false,
}) => {
  const breakLine = showBreakline ? (
    <hr className={styles.breakLine} />
  ) : null;
  return (
    <>
      <div className={styles.headerTag} id={removeHashSignFromLink(hashTag)}> </div>
      <Container className={styles.container}>
        {breakLine}
        <Row className={styles.header}>
          <Col
            className={styles.headerTitle}
            xs={{ span: 12, order: 2 }}
            sm={{ span: 12, order: 2 }}
            md={{ span: 12, order: 2 }}
            lg={{ span: 5, order: 1 }}
            xl={{ span: 5, order: 1 }}
          >
            <h1 className={styles.title}>
              <FormattedMessage id={title} />
            </h1>
            <h2 className={styles.description}>
              <FormattedMessage id={description} />
            </h2>
          </Col>
          <Col
            xs={{ span: 12, order: 1 }}
            sm={{ span: 12, order: 1 }}
            md={{ span: 12, order: 1 }}
            lg={{ span: 7, order: 2 }}
            xl={{ span: 7, order: 2 }}
            className={styles.image}
          >
            <GatsbyImage fluid={titleImage.childImageSharp.fluid} />
          </Col>
        </Row>
        {children}
      </Container>
    </>
  );
};

export default FeatureSectionWrapper;
