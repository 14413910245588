import React from 'react';
import Menu from 'helpers/constants/menuConstants.json';
import SEO from 'components/seo';
import Index, { IndexProps } from 'components/Layout';
import Features from 'components/Features';
import PageWrapper from 'components/PageWrapper';
import BannerInnerLink, { Pages } from 'components/Banners/BannerInnerLink';
import { FlattenJson } from 'helpers/utils/url';
import { flatten } from 'flat';
import BannerTransform from 'components/Banners/BannerTransform';

const menuFlatten: FlattenJson = flatten(Menu);
const FeaturesPage: React.FC<IndexProps> = ({ pageContext }) => (
  <Index pageContext={pageContext}>
    <SEO pageTitleTermPath="features" />
    <PageWrapper withBottomBackground>
      <Features />
    </PageWrapper>
    <BannerInnerLink
      page={Pages.Features}
      urlToPage={menuFlatten[`HEADER.PRICING.URL.${pageContext.language}`]}
    />
    <BannerTransform />
  </Index>
);

export default FeaturesPage;
