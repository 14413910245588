import React from 'react';
import { Container } from 'react-bootstrap';

import MainFeatures from './MainFeatures';

const Features: React.FC = () => (
  <Container>
    <MainFeatures />
  </Container>
);

export default Features;
