/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import styles from './Block.module.scss';

interface FeatureBlockProps {
  iconShiftMore?: boolean;
  title: string;
  description: string;
  inactive?: boolean;
  notice?: string;
}

const FeatureBlock: React.FC<FeatureBlockProps> = ({
  children,
  iconShiftMore = false,
  title,
  description,
  inactive = false,
  notice = '',
}) => {
  const titleCl = !inactive ? styles.title : styles.titleInactive;
  const inactiveTitlePostfix = inactive ? '*' : '';
  const inactiveNotice = inactive ? 'components.features.inactive' : '';
  const theNotice = notice !== '' ? notice : inactiveNotice;
  const iconShift = iconShiftMore ? styles.colIconShiftMore : {};

  const isNotice = theNotice !== '';
  const noteSection = isNotice
    ? (
      <Row>
        <Col>
          <p className={styles.notice}>
            <FormattedMessage id={theNotice} />
          </p>
        </Col>
      </Row>
    ) : null;
  const descriptionBottom = isNotice ? styles.padWithNotice : '';

  return (
    <Col className={styles.container} sm="6" md="4" lg="4" xl="4">
      <Row>
        <Col xs="2" sm="2" md="12" lg="12" xl="12" className={`${styles.colIcon} ${iconShift}`}>
          {children}
        </Col>
        <Col xs="10" sm="10" md="10" lg="12" xl="12" className={styles.colText}>
          <Row>
            <Col>
              <h3 className={titleCl}>
                <FormattedMessage id={title} />
                {inactiveTitlePostfix}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className={`${styles.description} ${descriptionBottom}`}>
                <FormattedMessage id={description} />
              </p>
            </Col>
          </Row>
          {noteSection}
        </Col>
      </Row>
    </Col>
  );
};

export default FeatureBlock;
