import React, { useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { isMobileOS, isTablet } from 'helpers/utils/mobile';
import GeneralSettings from 'helpers/constants/settings';

import AnchorLinks from '../links';
import SectionWrapper from '../Core/SectionWrapper';
import PlatformSection from './platform';

const MainFeatures: React.FC = () => {
  const [isMobile, setMobile] = useState(false);

  const updateWidth = (): void => {
    const isMobileDevice = (isMobileOS() && !isTablet())
    || window.innerWidth < GeneralSettings.MOBILE_VIEW.FEATURES_INTRO;
    setMobile(isMobileDevice);
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return (): void => window.removeEventListener('resize', updateWidth);
  }, []);

  const {
    imageIntro,
    imageIntroMobile,
  } = useStaticQuery(
    graphql`
        query {
            imageIntro: file(relativePath: { eq: "features/main.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64,
                    }
                }
            }
            imageIntroMobile: file(relativePath: { eq: "features/main-mobile.png" }) {
              childImageSharp {
                  fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64,
                  }
              }
          }
        }
    `,
  );

  const image = !isMobile ? imageIntro : imageIntroMobile;
  return (
    <SectionWrapper
      title="components.features.main.title"
      titleImage={image}
      description="components.features.main.description"
      hashTag={AnchorLinks.adminFeatures}
    >
      <PlatformSection />
    </SectionWrapper>
  );
};

export default MainFeatures;
