import React from 'react';
import { SectionConfigType } from 'types/features';

import dialPadIcon from 'images/features/platform/dialpad.svg';
import queryBuilderIcon from 'images/features/platform/query-builder.svg';
import smsIcon from 'images/features/platform/sms.svg';
import mmsIcon from 'images/features/platform/mms.svg';
import voicemailIcon from 'images/features/platform/voicemail.svg';
import swapIcon from 'images/features/platform/swap.svg';
import tuneIcon from 'images/features/platform/tune.svg';

import mobileFriendlyIcon from 'images/features/platform/mobile-friendly.svg';
import eventIcon from 'images/features/platform/event.svg';
import publicIcon from 'images/features/platform/public.svg';
import micIcon from 'images/features/platform/mic.svg';
import sysIcon from 'images/features/platform/system.svg';
import visOffIcon from 'images/features/platform/visibility-off.svg';

import AnchorLinks from '../links';
import Section from '../Core/Section';
import Block from '../Core/Block';

const plTranslationRoot = 'components.features.main.platform';

const PlatformConfig: Array<SectionConfigType> = [
  {
    title: `${plTranslationRoot}.feature1.title`,
    description: `${plTranslationRoot}.feature1.description`,
    colIconShiftMore: true,
    icon: dialPadIcon,
  },
  {
    title: `${plTranslationRoot}.feature2.title`,
    description: `${plTranslationRoot}.feature2.description`,
    icon: queryBuilderIcon,
  },
  {
    title: `${plTranslationRoot}.feature3.title`,
    description: `${plTranslationRoot}.feature3.description`,
    icon: smsIcon,
  },
  {
    title: `${plTranslationRoot}.feature4.title`,
    description: `${plTranslationRoot}.feature4.description`,
    icon: mmsIcon,
  },
  {
    title: `${plTranslationRoot}.feature5.title`,
    description: `${plTranslationRoot}.feature5.description`,
    icon: voicemailIcon,
  },
  {
    title: `${plTranslationRoot}.feature6.title`,
    description: `${plTranslationRoot}.feature6.description`,
    icon: swapIcon,
  },
  {
    title: `${plTranslationRoot}.feature7.title`,
    description: `${plTranslationRoot}.feature7.description`,
    // @todo to delete ? notice: `${plTranslationRoot}.feature7.notice`,
    icon: tuneIcon,
  },

  {
    title: `${plTranslationRoot}.feature8.title`,
    description: `${plTranslationRoot}.feature8.description`,
    icon: mobileFriendlyIcon,
  },
  {
    title: `${plTranslationRoot}.feature9.title`,
    description: `${plTranslationRoot}.feature9.description`,
    icon: eventIcon,
  },
  {
    title: `${plTranslationRoot}.feature10.title`,
    description: `${plTranslationRoot}.feature10.description`,
    icon: publicIcon,
  },
  {
    title: `${plTranslationRoot}.feature11.title`,
    description: `${plTranslationRoot}.feature11.description`,
    icon: micIcon,
  },
  {
    title: `${plTranslationRoot}.feature12.title`,
    description: `${plTranslationRoot}.feature12.description`,
    icon: sysIcon,
  },
  {
    title: `${plTranslationRoot}.feature13.title`,
    description: `${plTranslationRoot}.feature13.description`,
    icon: visOffIcon,
  },
];

const PlatformSection: React.FC = () => {
  const PlatformBlockArray = PlatformConfig.map((item) => (
    <Block
      key={item.title}
      iconShiftMore={item.colIconShiftMore || false}
      title={item.title}
      description={item.description}
      notice={item.notice || ''}
      inactive={item.inactive || false}
    >
      <img src={item.icon} alt="featureImage" />
    </Block>
  ));
  return (
    <Section
      hashTag={AnchorLinks.webPlatform}
      showBreakLine={false}
    >
      {PlatformBlockArray}
    </Section>
  );
};

export default PlatformSection;
